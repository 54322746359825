<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Host Billing</h5>
            </div>
            <div class="card">
                <div class="p-d-flex p-ai-center p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="emp_name" :options="adminList" @change="getClientsWiseBillingDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select admin"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <div class="p-d-flex p-mr-3">
                        <Dropdown v-model="host_name" :options="hostList" @change="getHostWiseBillingDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select host"
                            :style="{ minWidth: '200px' }" />
                    </div>
                    <!--<div class="p-d-flex ">
                        <Dropdown v-model="branch_name" :options="branchList" @change="getHostBranchEmpDetails($event)"
                            optionLabel="label" appendTo="body" class="p-text-capitalize" placeholder="Select branch"
                            :style="{ minWidth: '200px' }" />
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <TabView v-if="userdatadisplayflag">
        <TabPanel header="Account Statement Details">
            <div class="card">
                <DataTable :value="accStmtList" :lazy="true" :paginator="true" :rows="10" :totalRecords="totalRecords"
                    :loading="loading" @page="onPageAcc($event)" class="p-datatable-users" data-key="ha1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column field="id" header="Sr." headerStyle="width:5%">
                        <template #body="{ index }">
                            {{ ++index }}
                        </template>
                    </Column>
                    <Column header="Date" field="date" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div v-if="data.ha7">{{ format_timestamp(data.ha7) }} </div>
                            <div v-if="data.ha13">{{ format_timestamp(data.ha13) }} </div>
                        </template>
                    </Column>
                    <Column header="Particular" field="ha23" headerStyle="width: 39%;">
                        <div v-if="data.ha23 != ''">{{ data.ha23 }}</div>
                        <div v-else> - </div>
                    </Column>
                    <Column header="Credit" headerStyle="width: 12%" field="creditAmount">
                        <template #body="{ data: { ha5: creditAmount } }">
                            <div class="muted-text">
                                {{ creditAmount ? formatCurrency(creditAmount) : 'N/A' }}
                            </div>
                        </template>
                    </Column>
                    <Column header="Debit" headerStyle="width: 12%" field="creditAmount">
                        <template #body="{ data: { ha12: debitAmount } }">
                            {{ debitAmount ? formatCurrency(debitAmount) : 'N/A' }}
                        </template>
                    </Column>
                    <Column header="Balance" headerStyle="width: 12%" field="creditAmount">
                        <template #body="{ data: { ha18: balance } }">
                            {{ balance ? formatCurrency(balance) : 'N/A' }}
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
        <TabPanel header="Tax Invoice Details">
            <div class="card">
                <DataTable :value="billlinginvoicelist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="taxTotalRecords" :loading="loading" @page="onPageTax($event)" class="p-datatable-users"
                    data-key="hb1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Total Amount : <span class="indigo-color"> {{
                                formatCurrency(this.totalTaxAmount ? this.totalTaxAmount : '0') }}</span>
                            </h5>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="invoicedate" header="Date" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <div>{{ data.hb6 }}</div>
                        </template>
                    </Column>
                    <Column header="Host Name / Invoice No" headerStyle="width: 40%">
                        <template #body="{ data }">
                            <div>
                                <div class="p-text-capitalize"><span v-tooltip.right="'Host Name'">{{ data.hb3 }}</span>
                                </div>

                                <small class="muted-text p-text-capitalize p-d-flex p-my-1"><span
                                        v-tooltip.right="'Invoice No.'">{{ data.hb5 }}</span></small>
                            </div>
                        </template>
                    </Column>
                    <Column header="Invoice Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="muted-text">{{ formatCurrency(data.hb7 ? data.hb7 : '0') }}</div>

                        </template>
                    </Column>
                    <Column header="Tax Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="muted-text"> {{ formatCurrency(data.hb8 ? data.hb8 : '0') }} </div>

                        </template>
                    </Column>
                    <Column header="Total Amount" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div> {{ formatCurrency(data.hb9 ? data.hb9 : '0') }} </div>

                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 12%">
                        <template #body="{ data }">

                            <a v-if="data.hb11 != '' && data.hb11 != null" class="download-btn" target="_blank"
                                :href="data.hb11" v-tooltip.left="'Download Invoice'"><i class="pi pi-download"></i></a>
                            <!-- <Button icon="pi pi-eye" v-tooltip.left="'View Details'"
                                class="p-button-rounded p-button-success p-ml-2" style="margin: right 2.5rem;"
                                @click="viewDetailDialogOpen(data)" /> -->

                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
        <TabPanel header="Payment Receipt Details">
            <div class="card">
                <DataTable :value="paymentreceiptlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="PRtotalRecords" :loading="loading" @page="onPagePay($event)" class="p-datatable-users"
                    data-key="hc1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Total Amount : <span class="indigo-color"> {{
                                formatCurrency(this.totalCountOfBr20 ? this.totalCountOfBr20 : '0') }}</span>
                            </h5>
                        </template>
                        <template v-slot:right>
                            <!-- <Button label="Filter" icon="pi pi-filter" class=" p-mr-2" @click="receiptFilterDialogOpen" /> -->
                            <!-- <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter"
                                @click="receiptFilterDialogOpen()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                                @click="receiptFilterDialogOpen()"></Button> -->

                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column field="receiptdate" header="System Receipt Date" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>{{ format_timestamp(data.hc5) }}</div>
                        </template>
                    </Column>
                    <Column field="paymentdate" header="Payment Date" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>{{ format_date(data.hc6) }}</div>
                        </template>
                    </Column>
                    <Column header="Admin Name" headerStyle="width: 28%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.hc3 }}</div>
                        </template>
                    </Column>
                    <Column header="Payment Mode" headerStyle="width: 13%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.hc7 == 1"> Payment Gateway </div>
                                <div v-else-if="data.hc7 == 2"> Cheque </div>
                                <div v-else-if="data.hc7 == 3"> NEFT / RTGS</div>
                                <div v-else-if="data.hc7 == 4"> IMPS</div>
                                <div v-else> NEFT / RTGS </div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Amount" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div class="muted-text">{{ formatCurrency(data.hc20) }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <!-- <Button icon="pi pi-undo" v-tooltip.left="'Regenrate Payment Receipt'"
                                class="p-button-rounded p-button-success p-ml-2 p-mr-2" style="margin: right 2.5rem;"
                                @click="regeneratePaymentReceipt(data)" /> -->

                            <a v-if="data.hc16 != '' && data.hc16 != null" class="download-btn" target="_blank"
                                :href="data.hc16"><i class="pi pi-download"></i></a>
                            <span v-if="data.hc16 == '' || data.hc16 == null">-</span>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
    </TabView>
</template>
<script>
import ApiService from '../service/ApiService';
import router from '@/router';
import moment from 'moment';

export default {
    data() {
        return {
            adminList: [],
            hostList: [],
            branchList: [],
            page_no: 0,
            EmpTotalRecords: 0,
            loading: false,
            accStmtList: [],
            emp_name: '',
            host_name: '',
            branch_name: '',
            userdatadisplayflag: false,
            admin_id: '',
            hostId: '',
            admin_list_status: false,
            host_list_status: false,
            itemsPerPage: 10,
            billlinginvoicelist: [],
            taxTotalRecords: 0,
            paymentreceiptlist: [],
            PRtotalRecords: 0,

        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getAdminListOnly();
        this.storage_path = JSON.parse(localStorage.masterBucketName);
    },
    methods: {
        editHostDetailsRedirect(data) {
            router.push({
                name: "hostDetails",
                params: { hostId: data.ha1 },
            });
        },
        getAdminListOnly(e) {
            this.ApiService.getAdminListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data.records;
                    if (data.data.records.length == 1) {
                        this.getClientsWiseBillingDetails({ value: data.data.records[0] });
                        this.emp_name = data.data.records[0];
                    }
                } else {
                    this.adminList = null;
                }
            });  
        },
        getHostListOnly(e) {
            this.ApiService.getHostListOnly({ clientId: e.value.value }).then((data) => {
                if (data.status == 200) {
                    this.hostList = data.data.records;
                } else {
                    this.hostList = null;
                }
            });
        },
        getClientsAccountStatementDetails(e) {
            this.ApiService.getClientsAccountStatementDetails(e).then((data) => {
                if (data.success === true) {
                    this.accStmtList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                }
            });
        },
        getClientsTaxInvoicesDetails(e) {
            this.ApiService.getClientsTaxInvoicesDetails(e).then((data) => {
                if (data.success === true) {
                    this.billlinginvoicelist = data.data;
                    this.taxTotalRecords = data.count;
                    this.loading = false;
                }
            });
        },
        getClientsPaymentReceiptDetails(e) {
            this.ApiService.getClientsPaymentReceiptDetails(e).then((data) => {
                if (data.success === true) {
                    this.paymentreceiptlist = data.data;
                    this.PRtotalRecords = data.count;
                    this.loading = false;
                }
            });
        },

        getClientsWiseBillingDetails(e) {
            this.userdatadisplayflag = true;
            this.getHostListOnly(e);
            this.loading = true;
            if (e) {
                this.admin_id = e.value.value;
            }
            this.getClientsAccountStatementDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.getClientsTaxInvoicesDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.getClientsPaymentReceiptDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.admin_list_status = true;
            this.host_list_status = false;
        },

        getHostWiseBillingDetails(e) {
            this.loading = true;
            if (e) {
                this.hostId = e.value.value;
            }
            this.getClientsAccountStatementDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.getClientsTaxInvoicesDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.getClientsPaymentReceiptDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            this.admin_list_status = false;
            this.host_list_status = true;
        },
        onPageAcc(e) {
            this.loading = true;
            this.tabIndex = 0;
            this.page_no = e.page;
            if (this.host_list_status)
                this.getClientsAccountStatementDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            else
                this.getClientsAccountStatementDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });

        },
        onPageTax(e) {
            this.loading = true;
            this.tabIndex = 1;
            this.page_no = e.page;
            if (this.host_list_status)
                this.getClientsTaxInvoicesDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            else
                this.getClientsTaxInvoicesDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });

        },
        onPagePay(e) {
            this.loading = true;
            this.tabIndex = 2;
            this.page_no = e.page;
            if (this.host_list_status)
                this.getClientsPaymentReceiptDetails({ clientId: this.admin_id, hostId: this.hostId, page_no: this.page_no, itemsPerPage: this.itemsPerPage });
            else
                this.getClientsPaymentReceiptDetails({ clientId: this.admin_id, page_no: this.page_no, itemsPerPage: this.itemsPerPage });

        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        formatCurrency(amount, currency = 'INR', minimumFractionDigits = 2) {
            let formatterAmount = new Intl.NumberFormat('en-IN', { minimumFractionDigits }).format(amount.toString());
            return (+0).toLocaleString('en-IN', { style: 'currency', currency })
                .replace('0.00', " " + formatterAmount)
        },
    },
};
</script>
<style scoped lang="scss"></style>
